<template>
	<div class="page-wrap">
		<system_setting_add_modal ref="system_setting_add_modal" @confirm="confirm_add_setting" />


		<div class="page-inner">


			<div class="page-ctx-wrap">
				<div class="page-title">
					系统设置
				</div>

				<div class="page-ctx">
					<div class="page-tip">
						注：点击标签名编辑配置信息
					</div>
					<table class="table-box" cellpadding="0" cellspacing="0">
						<thead>
							<tr>
								<th>类型</th>
								<th>选项</th>
								<!-- <th>年份</th> -->
							</tr>
						</thead>
						<tbody>
							<tr class="loop-item" data-id="1" v-for="(v1, v1_index) in dataList" :key="v1_index">
								<td>
									<div class="box-type flex-between">
										<div class="type-title">
											<!-- closable -->
											<el-tag class="cus-tag-item" :disable-transitions="false" @close="handleCloseV1(v1)"
											 @click="do_edit_v1(v1)"
											>
												{{ v1.title }}{{ v1.id }}
											</el-tag>
											<!-- <span class="tag-item" :data-id="v1.id"> {{ v1.title }} </span> -->
										</div>
										<!-- <div class="type-acts">
											<el-button type="text" @click="do_edit_v1(v1)">编辑</el-button>
										</div> -->
									</div>
								</td>
								<td>
									<!-- <span
						          v-for="(v2, v2_index) in v1.child"
						          :key="v2.id"
						          class="tag-item checked"
						          :data-id="v2.id"
						        >
						          {{ v2.title }}
						        </span> -->
									<el-tag  size="small" type="warning" class="cus-tag-item" v-for="(v2, v2_index) in v1.child" :key="v2.id" closable
										:disable-transitions="false" @close="handleCloseV2(v2)" @click="do_tag_click(v2,v1)">
										{{ v2.title }}
									</el-tag>

									<!-- <el-input
						          class="input-new-tag"
						          v-if="v1.inputVisible"
						          v-model="v1.inputValue"
						          :ref="v1.ref"
						          size="small"
						          @keyup.enter.native="handleInputConfirm"
						          @blur="handleInputConfirm"
						        >
						        </el-input> -->

									<input type="text" class="cus-tag-input cus-v2-input" :data-id="v1.id" v-if="v1.inputVisible"
										v-model="v1.inputValue" :ref="v1.ref" size="small" @keyup.enter="handleInputConfirm(v1)"
										@blur="handleInputConfirm(v1)" />

									<el-button v-else class="button-new-tag" size="small" @click="showInput(v1)">+ 新增</el-button>
								</td>
							</tr>

							<!-- 一级分类新增 -->
							<tr>
								<td>
									<input type="text" class="cus-tag-input cus-v1-input" v-if="add_inputVisible" v-model="add_inputValue"
										ref="add_saveTagInput" size="small" @keyup.enter="add_handleInputConfirm()"
										@blur="add_handleInputConfirm()" />

									<el-button  v-else class="button-new-tag" size="small" @click="add_showInput()">+ 新增</el-button>
								</td>
								<td>-</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>


	</div>
</template>

<script>
	// 岗位状态

	import system_setting_add_modal from '@/components/account/system_setting_add_modal.vue'

	import {
		mapState
	} from "vuex";
	export default {
		name: "news",
		components: {
			system_setting_add_modal
		},
		data() {
			return {
				dataList: [],
				add_inputVisible: false,
				add_inputValue: "",
			};
		},
		computed: {},
		watch: {},
		created() {
			this.setView();
		},
		mounted() {},
		methods: {
			setView() {
				this.$api(
					'getTypeList', {

					}).then((res) => {
					console.warn("获取分类配置", res);
					if (res.code == 200) {
						let list = res.data;
						list.forEach((v) => {
							v.inputVisible = false;
							v.inputValue = "";
							v.ref = "saveTagInput" + v.id;
						});

						let v1_list = list.filter(v => v.parent_id == 0)
						let v2_list = list.filter(v => v.parent_id != 0)
						v1_list.forEach(v1 => {
							let child = v2_list.filter(v2 => v2.parent_id == v1.id) || []
							v1.child = child
						})
						this.dataList = v1_list;
						// this.dataList = list;
					}
				});

			},

			handleCloseV1(v1) {
				this.$confirm('是否删除?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$api('delType',{
						id:v1.id
					},'post').then((res) => {
						if (res.code == 200) {
							this.setView();
						}
					});
				}).catch(() => {
					         
				});
				
				
			},

			handleCloseV2(v2) {
				this.$confirm('是否删除?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$api('delType',{
						id:v2.id
					},'post').then((res) => {
						if (res.code == 200) {
							this.setView();
						}
					});
				}).catch(() => {
					         
				});

				// this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);

				// this.$api('delType',{
				// 		id:v2.id
				// },'post').then((res) => {
				// 	if (res.code == 200) {
				// 		this.setView();
				// 	}
				// });
			},

			showInput(v1) {
				console.warn("分组", {
					...v1
				});
				v1.inputVisible = true;

				this.$nextTick((_) => {
					// console.warn("输入框", this.$refs[v1.ref]);
					// console.warn("输入框", this.$refs[v1.ref].$refs.input);
					// this.$refs[v1.ref].$refs.input.focus();

					// this.$refs[v1.ref].focus();

					let dom = document.querySelector('.cus-v2-input[data-id="' + v1.id + '"]');
					console.warn("dom", dom);
					if (dom) {
						dom.focus();
					}
				});
			},

			async handleInputConfirm(v1) {
				console.warn('新增2级分类', {
					...v1
				})

				let inputValue = v1.inputValue.trim();
				if (inputValue) {
					// this.dynamicTags.push(inputValue);
					//新增当前分类
					// api;

					let child = v1.child || []
					let has_same_name = child.find((v) => v.title == inputValue);

					if (has_same_name) {
						alertErr("当前分类名称已存在！");
					} else {
						let res_add = await this.$api('editType', {
							id: 0,
							title: inputValue, //名称
							parent_id: v1.id, //父级id
							description: '',
						}, 'post');

						if (res_add.code == 200) {
							this.setView();
						}
					}
				}
				v1.inputVisible = false;
				v1.inputValue = "";
			},

			// 新增一级分类
			add_showInput() {
				this.add_inputVisible = true;

				this.$nextTick((_) => {
					let dom = document.querySelector(".cus-v1-input");
					console.warn("dom", dom);
					if (dom) {
						dom.focus();
					}
				});
			},

			async add_handleInputConfirm() {
				let inputValue = this.add_inputValue.trim();
				if (inputValue) {
					// this.dynamicTags.push(inputValue);
					// 新增当前分类
					// api;
					let has_same_name = this.dataList.find((v) => v.title == inputValue);
					if (has_same_name) {
						alertErr("当前分类名称已存在！");
					} else {
						let res_add = await this.$api('editType', {
							id: 0,
							title: inputValue, //名称
							parent_id: 0, //父级id
							description: '',
						}, 'post');
						if (res_add.code == 200) {
							this.setView();
						}
					}
				}
				this.add_inputVisible = false;
				this.add_inputValue = "";
			},


			do_edit_v1(v1) {
				this.$log('修改', v1)
				this.$refs.system_setting_add_modal.init(v1)
			},

			confirm_add_setting() {
				this.setView()
			},

			//标签编辑
			do_tag_click(v2, v1) {
				this.$log('2级标签', v2)
				this.$refs.system_setting_add_modal.init({
					...v2,
					parent_title: v1.title
				})
			}
			// 新增一级分类
		},
	};
</script>

<style scoped lang="less">
	.page-wrap {
		.page-inner {}
	}

	.page-ctx-wrap {
		background: #fff;


		.page-title {
			color: #333333;
			font-size: 14px;
			font-weight: bold;
			padding: 12px 17px;
			border-bottom: 1px solid #EDF0F6;
		}


		.page-ctx {
			padding: 20px;
			border-radius: 6px;
			
			.page-tip {
				margin-bottom: 20px;
				font-size: 14px;
				color: coral;
			}
		}
	}

	// 标签样式
	.cus-tag-item {
		// margin-right: 15px;
		// margin-top: 5px;
		// margin-bottom: 5px;
		margin: 5px 8px;
	}

	.el-tag {
		cursor: pointer;
	}

	.el-tag+.el-tag {
		margin-left: 10px;
	}

	.button-new-tag {
		margin-left: 10px;
		height: 32px;
		line-height: 30px;
		padding-top: 0;
		padding-bottom: 0;
	}

	.input-new-tag {
		width: 90px;
		margin-left: 10px;
		vertical-align: bottom;
	}

	.cus-tag-input {
		-webkit-appearance: none;
		background-color: #fff;
		background-image: none;
		border-radius: 4px;
		border: 1px solid #dcdfe6;
		box-sizing: border-box;
		color: #606266;
		display: inline-block;
		outline: 0;
		padding: 0 15px;
		width: 90px;
		height: 32px;
		line-height: 32px;
		transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

		&:focus {
			border-color: #66b1ff;
		}
	}

	// 标签样式

	.table-box {
		width: 100%;
		border-collapse: 0;
		/* table-layout: fixed; */
		border: 1px solid #eee;
		font-size: 14px;
	}

	.table-box tr {
		/* min-height: 40px; */
	}

	.table-box th {
		text-align: left;
		padding: 10px 10px;

		border-right: 1px solid #eee;
	}

	.table-box td {
		text-align: left;
		padding: 4px 10px;
		border-top: 1px solid #eee;
		border-right: 1px solid #eee;
	}

	.table-box td:nth-child(1) {
		width: 10%;
	}

	.table-box td:nth-child(2) {
		width: 80%;
	}

	.table-box .tag-item {
		cursor: pointer;
		outline: none;
		user-select: none;
		margin-right: 10px;
		display: inline-flex;
		align-items: center;
		color: #333;
	}

	.table-box .tag-item:before {
		content: "";
		width: 20px;
		height: 20px;
		display: inline-block;
		background-image: url("https://changkun.dx.hdapp.com.cn/erp/images/check-0.png");
		background-size: 100% 100%;
		background-position: center;
		background-repeat: no-repeat;
		margin-right: 3px;
	}

	.table-box .tag-item.checked:before {
		background-image: url("https://changkun.dx.hdapp.com.cn/erp/images/check-1.png");
	}
</style>